import React, { useState, useEffect } from 'react';
import logo from "../assets/images/transistor-white.png"

function Home() {
    const [showBar, setShowBar] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowBar(true);
      }, 1000); // Show bar after 1 second
  
      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }, []);
  
    const closeBar = () => {
      setShowBar(false);
    };
    return (
        <div className="Home">
            {showBar && (
            <div className="notification-bar">
              <span>Leave us a review on Google! <a href="https://g.page/r/Cf1uvChN02zIEAI/review">https://g.page/r/Cf1uvChN02zIEAI/review</a></span>
              <button className="close-btn" onClick={closeBar}>[X]</button>
            </div>
          )}
            <div className="home-container">
                <div className="front-page-text">
                    <h1>Daedalus<br/>Solutions</h1>
                    <h2>Streamlined, affordable hosting and web development.</h2>
                    <h3>WordPress hosting plans starting at just C$4/month.</h3>
                </div>
                <div>
                    <img src={logo} className="front-page-logo" height={600} />
                </div>
            </div>
        </div>
    );
}

export default Home;