import * as React from "react";

function Footer() {
    return (
        <footer className="footer">
            <p>Daedalus Solutions - ©2025</p>
            <p className="footer-links"><a href="/privacy-policy">Privacy Policy</a> - <a href="/terms-of-service">Terms of Service</a> - <a href="/careers">Careers</a></p>
            
        </footer>
    );
}

export default Footer;