import React, { useState, useRef, useEffect } from 'react';

const Careers = () => {
    const [showBar, setShowBar] = useState(false);

    useEffect(() => {
    const timer = setTimeout(() => {
        setShowBar(true);
    }, 1000); // Show bar after 1 second

    // Cleanup timer on unmount
    return () => clearTimeout(timer);
    }, []);
      
        const closeBar = () => {
          setShowBar(false);
        };
  // State to control the modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  
  const modalContentRef = useRef(null);

  // Handle opening and closing the modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setFormSubmitted(false); // Reset submission state if the modal is reopened
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true); // Set the form submission state to true
  
    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
    };
  
    // Construct the query string
    const queryString = new URLSearchParams(formData).toString();
  
    // Send the form data as URL parameters in the GET request
    fetch(`${process.env.REACT_APP_BACKEND_URL}/apply?${queryString}`, {
        method: 'POST',
      })
        .then((response) => {
          // Check if the response is JSON
          if (response.ok) {
            return response.json();
          } else {
            return response.text().then(text => { throw new Error(text) });
          }
        })
        .then((data) => {
          setTimeout(() => {
            setIsModalOpen(false); // Close the modal after 3 seconds
          }, 3000);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  };

  return (
    <div style={styles.container}>
                    {showBar && (
            <div className="notification-bar">
              <span>Leave us a review on Google! <a href="https://g.page/r/Cf1uvChN02zIEAI/review">https://g.page/r/Cf1uvChN02zIEAI/review</a></span>
              <button className="close-btn" onClick={closeBar}>[X]</button>
            </div>
          )}
      <h1 style={styles.header}>Join Daedalus Solutions!</h1>
      <p style={styles.subHeader}>We’re looking for passionate developers to join our team.</p>

      <div style={styles.jobCard}>
        <h2 style={styles.jobTitle}>Frontend Developer</h2>
        <p style={styles.jobDescription}>
          Daedalus Solutions offers effective frontend web designs and reliable WordPress hosting. We are looking for a talented and motivated Frontend Developer to join our growing team.
        </p>
        <button style={styles.applyButton} onClick={toggleModal}>
          Apply Now
        </button>
      </div>

            {formSubmitted && (
        <div style={styles.popup}>
            <div style={styles.popupContent}>
            <h2>Submitted</h2>
            <p>Your application has been successfully submitted.</p>
            <button
                style={styles.closeButton}
                onClick={() => setFormSubmitted(false)} // Close the message box
            >
                Close
            </button>
            </div>
        </div>
        )}

      {/* Modal for job description */}
      {isModalOpen && (
        <div style={styles.modalOverlay}>
          <div ref={modalContentRef} style={styles.modal}>
            <div style={styles.leftSide}>
              <h3>Application Form</h3>
                <form onSubmit={handleFormSubmit}>
                <label htmlFor="name">Full Name</label>
                <input type="text" id="name" name="name" required placeholder="Enter your full name" style={styles.input} />

                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" required placeholder="Enter your email" style={styles.input} />

                <label htmlFor="phone">Phone Number</label>
                <input type="tel" id="phone" name="phone" required placeholder="Enter your phone number" style={styles.input} />

                <label htmlFor="resume">Resume</label>
                <input type="file" id="resume" name="resume" accept=".pdf,.doc,.docx" required style={styles.input} />

                <label htmlFor="experience">Do you have experience with frontend development frameworks? (React, Vue, Angular etc.)</label>
                <textarea
                  required
                  placeholder=""
                  style={styles.textarea}
                ></textarea>

                <label htmlFor="why_apply">Do you have experience with WordPress?</label>
                <textarea
                  id="why_apply"
                  name="why_apply"
                  required
                  placeholder=""
                  style={styles.textarea}
                ></textarea>

                <label htmlFor="why_apply">Do you have experience with PHP?</label>
                <textarea
                  id="why_apply"
                  name="why_apply"
                  required
                  placeholder=""
                  style={styles.textarea}
                ></textarea>

                <label htmlFor="citizenship">Are you a Canadian Citizen or Permanent Resident?</label>
                <select id="citizenship" name="citizenship" required style={styles.input}>
                  <option value="">Select</option>
                  <option value="citizen">Canadian Citizen</option>
                  <option value="permanent_resident">Permanent Resident</option>
                  <option value="other">Other</option>
                </select>

                <label htmlFor="work_authorization">Do you have work authorization for this position in Canada?</label>
                <select id="work_authorization" name="work_authorization" required style={styles.input}>
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>

                <label htmlFor="available_start_date">Available Start Date</label>
                <input type="date" id="available_start_date" name="available_start_date" required style={styles.input} />

                <label htmlFor="previous_employment">Have you previously worked remotely for a full-time position?</label>
                <select id="previous_employment" name="previous_employment" required style={styles.input}>
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>

                <button type="submit" style={styles.submitButton}>Submit Application</button>
              </form>
            </div>

            <div className='job-description' style={styles.rightSide}>
              <h2>Company Description</h2>
              <p>
                Daedalus Solutions is a dynamic and innovative technology company
                specializing in delivering high-quality frontend web designs and
                reliable WordPress hosting solutions. We are committed to providing
                cutting-edge, user-centric web solutions for businesses looking to
                establish a strong digital presence. At Daedalus Solutions, we focus
                on optimizing user experiences and ensuring our clients' websites are
                not only visually stunning but also technically robust and scalable.
              </p>

              <h2>Role Description</h2>
              <p>
                We are seeking a talented and motivated <strong>Frontend Developer</strong> to
                join our growing team. This is a full-time remote position where you
                will be responsible for designing, developing, and implementing modern
                user interface components for web applications. As a Frontend Developer
                at Daedalus Solutions, you will work closely with UI/UX designers,
                back-end developers, and other stakeholders to create seamless,
                intuitive, and visually appealing web experiences.
              </p>

              <h3>Key Responsibilities:</h3>
              <ul>
                <li><strong>Develop and Implement User Interfaces:</strong> Design and implement responsive web pages using HTML, CSS, JavaScript, and other relevant web technologies.</li>
                <li><strong>Collaborate with Designers and Back-End Developers:</strong> Work closely with designers to bring UI/UX designs to life.</li>
                <li><strong>Responsive Web Design & Optimization:</strong> Ensure mobile-first web applications and optimize for various screen sizes.</li>
                <li><strong>Code Quality and Organization:</strong> Maintain clean and well-organized code.</li>
                <li><strong>Stay Updated with Industry Trends:</strong> Continuously research emerging frontend technologies and best practices.</li>
                <li><strong>Debugging & Troubleshooting:</strong> Solve complex web issues across browsers and devices.</li>
                <li><strong>Collaborative Development:</strong> Participate in team meetings and code reviews.</li>
              </ul>

              <h3>Qualifications:</h3>
              <ul>
                <li><strong>Proficiency in Front-End Development:</strong> Solid experience with HTML, CSS, JavaScript, and front-end frameworks.</li>
                <li><strong>Responsive Web Design Expertise:</strong> Experience creating responsive designs using CSS frameworks (e.g., Bootstrap, Tailwind CSS).</li>
                <li><strong>Experience with Back-End Integration:</strong> Familiarity with back-end technologies like PHP, Node.js.</li>
                <li><strong>Modern Development Frameworks:</strong> Experience with React, Vue.js, or Angular, and understanding of state management.</li>
                <li><strong>Strong Problem-Solving and Debugging Skills:</strong> Ability to troubleshoot and find solutions quickly.</li>
                <li><strong>Independent and Remote Work Skills:</strong> Ability to work autonomously in a remote setting.</li>
                <li><strong>Version Control Systems:</strong> Familiarity with Git.</li>
                <li><strong>Experience with WordPress is a Plus:</strong> Exposure to WordPress development is beneficial.</li>
              </ul>

              <h3>Additional Skills (Preferred but not required):</h3>
              <ul>
                <li>Experience with modern CSS methodologies (Flexbox, CSS Grid, SASS/SCSS).</li>
                <li>Familiarity with build tools like Webpack, Gulp, or npm scripts.</li>
                <li>Basic knowledge of SEO best practices.</li>
                <li>Experience with RESTful APIs and third-party service integrations.</li>
              </ul>

              <h2>Why Join Daedalus Solutions?</h2>
              <ul>
                <li><strong>Remote Flexibility:</strong> Work from anywhere while being part of a collaborative team.</li>
                <li><strong>Growth Opportunities:</strong> Opportunities for continuous learning and career growth.</li>
                <li><strong>Innovative Environment:</strong> Work with a team passionate about delivering top-notch solutions.</li>
                <li><strong>Work-Life Balance:</strong> We promote a healthy work-life balance.</li>
              </ul>

              <p>
                If you're passionate about frontend development and enjoy working in a dynamic, collaborative environment, we’d love to hear from you!
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Styles
const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#000',
    minHeight: '100vh',
  },
  header: {
    fontSize: '3rem',
    color: '#fff',
  },
  subHeader: {
    fontSize: '1.5rem',
    color: '#fff',
    marginBottom: '20px',
  },
  jobCard: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    margin: 'auto',
  },
  jobTitle: {
    fontSize: '2rem',
    color: '#000',
    marginBottom: '10px',
  },
  jobDescription: {
    fontSize: '1rem',
    color: '#000',
    marginBottom: '20px',
  },
  applyButton: {
    padding: '10px 20px',
    fontSize: '1.1rem',
    color: 'white',
    backgroundColor: '#000',
    cursor: 'pointer',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    maxWidth: '1000px',
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxHeight: '80vh',
    overflowY: 'auto', // Ensures the modal is scrollable if content overflows
  },
  leftSide: {
    width: '50%',
    paddingRight: '20px',
    paddingTop: '10px',
    textAlign: 'left',
  },
  rightSide: {
    width: '50%',
    paddingLeft: '20px',
    paddingTop: '10px',
    textAlign: 'left',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    height: '60px',
    resize: 'none',
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  submitButton: {
    padding: '10px 20px',
    fontSize: '1rem',
    color: 'white',
    backgroundColor: '#000',
    cursor: 'pointer',
  },
  closeButton: {
    marginTop: '20px',
    padding: '8px 15px',
    backgroundColor: '#ccc',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  popup: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  popupContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    width: '300px',
  },
  closeButton: {
    padding: '10px 20px',
    backgroundColor: '#000',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '20px',
  },
};

export default Careers;
