import logo from './logo.svg';
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Pricing from './components/Pricing';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from "./components/Dashboard";
import Settings from "./components/Settings"
import UpdateEmail from './components/UpdateEmail';
import UpdatePassword from './components/UpdatePassword.js';
import Checkout from './components/Checkout.js';
import Receipt from './components/Receipt.js';
import AddWebsite from './components/AddWebsite.js';
import ForgotPassword from './components/ForgotPassword.js';
import ResetPassword from './components/ResetPassword.js';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import TermsOfService from './components/TermsOfService.js';
import BlogArticle from './components/BlogArticle.js';
import Careers from './components/Careers.js';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

function App() {

  return (
    <div className='App'>
      <Elements stripe={stripePromise}>
        <Router>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/settings" element={<Settings/>}/>
              <Route path="/update-email" element={<UpdateEmail/>}/>
              <Route path="/update-password" element={<UpdatePassword/>}/>
              <Route path="/checkout/:id" element={<Checkout/>}/>
              <Route path="/receipt/:id" element={<Receipt/>}/>
              <Route path="/add-website" element={<AddWebsite/>}/>
              <Route path="forgot-password" element={<ForgotPassword/>}/>
              <Route path="reset-password/:token" element={<ResetPassword/>}/>
              <Route path="privacy-policy" element={<PrivacyPolicy />}/>
              <Route path="terms-of-service" element={<TermsOfService />}/>
              <Route path="/careers" element={<Careers />}/>
              <Route path="/:slug" element={<BlogArticle />} />
            </Routes>
            <Footer />
        </Router>
      </Elements>
    </div>
  );
}

export default App;
